import React from 'react';
import '../../pages/Shared/Shared.css';

export const Seniors = () => {
    return <table className="table table-bordered">
            <tbody>
            <tr>
            <th>Name</th>
            <th>Captain</th>
            </tr>
            <tr>
            <td><a href="https://paignton.play-cricket.com/Teams/24330" target="_blank" rel="noopener noreferrer">1st XI</a></td>
            <td><a href="https://paignton.play-cricket.com/player_stats/batting/6097992?rule_type_id=179" target="_blank" rel="noopener noreferrer"> Noah Wright</a></td>
            </tr>
            <tr>
            <td><a href="https://paignton.play-cricket.com/Teams/24329" target="_blank" rel="noopener noreferrer">2nd XI</a></td>
            <td><a href="https://paignton.play-cricket.com/player_stats/batting/697979?rule_type_id=179" target="_blank" rel="noopener noreferrer"> Connor Bryan</a></td>
            </tr>
            <tr>
            <td><a href="https://paignton.play-cricket.com/Teams/223452" target="_blank" rel="noopener noreferrer">3rd XI</a></td>
            <td><a href="https://paignton.play-cricket.com/player_stats/batting/4975469?rule_type_id=179" target="_blank" rel="noopener noreferrer"> Jon Robers</a></td>
            </tr>
            <tr>
            <td><a href="https://paignton.play-cricket.com/Teams/256748" target="_blank" rel="noopener noreferrer">4th XI</a></td>
            <td><a href="https://paignton.play-cricket.com/player_stats/batting/4327082?rule_type_id=179" target="_blank" rel="noopener noreferrer"> Nick Glanfield</a></td>
            </tr>
            <tr>
            <td><a href="http://paignton.play-cricket.com/website/team_widgets/128313" target="_blank" rel="noopener noreferrer">Twenty20</a></td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td><a href="http://paignton.play-cricket.com/website/team_widgets/176291" target="_blank" rel="noopener noreferrer">Friendly XI</a></td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td><a href="http://paignton.play-cricket.com/website/team_widgets/227515" target="_blank" rel="noopener noreferrer">Womens 1st XI</a></td>
            <td>&nbsp;</td>
            </tr>
        </tbody>
    </table>
}