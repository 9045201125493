import React from "react";
import './About.css';

export const MiniAbout = () => {
    return <div className="MiniAbout">
        <h4 className="Title">ABOUT PAIGNTON CRICKET CLUB</h4>
        <span>Queens Park</span><br/>
        <span>Queens Road</span><br/>
        <span>Paignton</span><br/>
        <span>Devon</span><br/>
        <span>TQ4 6AT</span><br/><br/>
        <span>Telephone: 01803 557715</span><br/>
        <span>Email: paigntoncricketclub@gmail.com</span> 
    </div>
}