import React from 'react';

export const ThreesMap = () => {
    return <div className="MiniAbout">
        <h4 className="Title">TORRE VALLEY 3RD & 4TH TEAM</h4>
        <iframe 
            width="100%"
            frameBorder="0"
            title="map"
            marginHeight="0"
            marginWidth="0"
            height="300px"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2539.6708015538!2d-3.545959784423747!3d50.4658545941361!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486d0f5ae9b78c55%3A0xdd5952d53bda70f2!2sTorre%20Valley%20North!5e0!3m2!1sen!2suk!4v1650920775338!5m2!1sen!2suk"
        />
    </div>
}